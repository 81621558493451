import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from './Filter.module.css';
import { JournalItemEnum } from '../../types/JournalItem';

const FilterType = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined">
      <Select
        labelId="journal-select"
        classes={{ root: styles.root }}
        id="type"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      >
        <MenuItem value="all">
          <em>{t('journal.filter.allSelection')}</em>
        </MenuItem>
        <MenuItem value={JournalItemEnum.entry}>
          {t('journal.filter.entrySelection')}
        </MenuItem>
        <MenuItem value={JournalItemEnum.activity}>
          {t('journal.filter.activitySelection')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FilterType;
