import firebase from 'gatsby-plugin-firebase';
import { JournalItem } from '../../types/JournalItem';

const getJournalItems = async (uid: string): Promise<JournalItem[] | null> => {
  try {
    const wplhItems = firebase
      .database()
      .ref(`WPLH`)
      .orderByChild('user')
      .equalTo(uid)
      .once('value');

    const journalEntryItems = firebase
      .database()
      .ref(`journalentries`)
      .orderByChild('user')
      .equalTo(uid)
      .once('value');

    const pieChartItems = firebase
      .database()
      .ref(`piechart`)
      .orderByChild('user')
      .equalTo(uid)
      .once('value');

    const sCurveItems = firebase
      .database()
      .ref(`scurve`)
      .orderByChild('user')
      .equalTo(uid)
      .once('value');

    const snapshots = await Promise.all([
      wplhItems,
      journalEntryItems,
      pieChartItems,
      sCurveItems,
    ]);

    const journalItems: JournalItem[] = snapshots.reduce(
      (collector, snapshot) => {
        const itemsObject = snapshot.val();
        if (itemsObject == null) {
          return collector;
        }
        const items: JournalItem[] = Object.values(itemsObject);
        collector.push(...items);
        return collector;
      },
      [] as JournalItem[],
    );

    return journalItems;
  } catch (error) {
    return null;
  }
};

export default getJournalItems;
