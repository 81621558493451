import { graphql, useStaticQuery, navigate } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
} from '@material-ui/core';

import TopNavbar from '../../../components/shared/TopNavbar';
import Button from '../../../components/shared/Button';
import NavigationButton from '../../../components/shared/NavigationButton';
import LoadingScreen from '../../../components/router/LoadingScreen';

import { User } from '../../../types/User';
import styles from './activity.module.css';

const Activity = ({ user }: { user: User }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const previews = useStaticQuery(graphql`
    query {
      wplh: file(relativePath: { eq: "activities/wplh.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      scurve: file(relativePath: { eq: "activities/scurve.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      piechart: file(relativePath: { eq: "activities/piechart.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  useEffect(() => {
    setLoading(false);
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoToWplh = () => {
    navigate('/app/journal/activity/wplh');
  };

  const handleGoToPieChart = () => {
    navigate('/app/journal/activity/piechart');
  };

  const handleGoToSCurve = () => {
    navigate('/app/journal/activity/scurve');
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <TopNavbar />
      <Container className={styles.activity}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <h1>{t('activity.landingPage.heading')}</h1>
          </Grid>
          <Grid item sm={6}>
            <NavigationButton onClick={handleBack} alignRight />
          </Grid>
          {[
            {
              image: previews.piechart.childImageSharp.fluid,
              title: t('activity.landingPage.piechart.title'),
              description: t('activity.landingPage.piechart.description'),
              onClick: handleGoToPieChart,
            },
            {
              image: previews.wplh.childImageSharp.fluid,
              title: t('activity.landingPage.wplh.title'),
              description: t('activity.landingPage.wplh.description'),
              onClick: handleGoToWplh,
            },
            {
              image: previews.scurve.childImageSharp.fluid,
              title: t('activity.landingPage.scurve.title'),
              description: t('activity.landingPage.scurve.description'),
              onClick: handleGoToSCurve,
            },
          ].map(({ image, title, description, onClick }) => (
            <Grid item sm={4}>
              <Card className={styles.card}>
                <div className={styles.imageContainer}>
                  <GatsbyImage
                    loading="eager"
                    fluid={image}
                    className={styles.image}
                  />
                </div>

                <CardContent className="text-center">
                  <Typography className="text-2xl font-semibold">
                    {title}
                  </Typography>
                  <Typography className="text-lg">{description}</Typography>
                </CardContent>
                <Button className={styles.button} onClick={onClick}>
                  {t('activity.landingPage.start')}
                </Button>
              </Card>
            </Grid>
          ))}

          {/* <Grid item sm={4}>
            <Card className={styles.card}>Coming Soon!</Card>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default Activity;
