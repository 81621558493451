import { navigate } from 'gatsby';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';

import { BsQuestionCircleFill } from 'react-icons/bs';
import IconButton from '@material-ui/core/IconButton';
import { MdDelete } from 'react-icons/md';

import LoadingScreen from '../../../components/router/LoadingScreen';
import TopNavbar from '../../../components/shared/TopNavbar';
import Button from '../../../components/shared/Button';

import useJournalEntry from '../../../hooks/useJournalEntry';
import uuid from '../../../utils/uuid';

import styles from './entry.module.css';
import NavigationButton from '../../../components/shared/NavigationButton';

import ModalContext from '../../../contexts/ModalContext';

const JournalEntry = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(true);
  const [showModified, setShowModified] = useState(false);

  const {
    loadJournalEntry,
    createJournalEntry,
    updateJournalEntryDetails,
    submitJournalEntry,
    deleteJournalEntry,
    journalEntry,
  } = useJournalEntry();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [createdAt, setCreatedAt] = useState(Date.now());

  const { t } = useTranslation();

  const { emitter, events } = useContext(ModalContext);

  const handleSubmit = async () => {
    submitJournalEntry();
    navigate(-1);
  };

  useEffect(() => {
    if (id == null) {
      const newJid = uuid();
      createJournalEntry(newJid);
    } else {
      (async () => {
        const data = await loadJournalEntry(id);
        if (!data) {
          navigate(-1);
          toast.error(t('journal.entry.toasts.doesNotExist'));
          return null;
        }
        setTitle(data.title);
        setContent(data.content);
        setCreatedAt(data.createdAt);
        setShowModified(true);
        setLoading(false);
      })();
    }
    setLoading(false);
  }, []);

  const handleDelete = () => {
    emitter.emit(events.DELETE_JOURNAL_ENTRY_MODAL, {
      id,
      title,
      deleteAction: deleteJournalEntry,
      isEntry: true,
    });
  };

  useEffect(() => {
    if (loading === true) {
      return;
    }

    updateJournalEntryDetails({
      title,
      content,
      createdAt,
    });
  }, [title, content, createdAt]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <TopNavbar />
      <Container>
        <NavigationButton onClick={() => navigate(-1)} alignRight />
        <Alert
          severity="info"
          variant="standard"
          icon={<BsQuestionCircleFill />}
          className={styles.alert}
        >
          <h2>
            <b>{t('journal.entry.prompts.header')}</b>
          </h2>
          <h4>{t('journal.entry.prompts.prompt1')}</h4>
          <h4> • {t('journal.entry.prompts.prompt2')}</h4>
          <h4> • {t('journal.entry.prompts.prompt3')}</h4>
          <h4> • {t('journal.entry.prompts.prompt4')}</h4>
          <h4> • {t('journal.entry.prompts.prompt5')}</h4>
          <h4> • {t('journal.entry.prompts.prompt6')}</h4>
          <h4>{t('journal.entry.prompts.prompt7')}</h4>

          <div className="mt-5 align-middle flex">
            <h4>{t('journal.entry.prompts.tip')}</h4>
          </div>
        </Alert>
        <Grid container className={styles.form} spacing={4}>
          <Grid item xs={11}>
            <TextField
              name="journal-title"
              id="journal-title"
              placeholder={t('journal.entry.title')}
              fullWidth
              margin="normal"
              InputProps={{
                classes: {
                  root: `${styles.field} ${styles.title}`,
                },
                disableUnderline: true,
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            {id && (
              <IconButton onClick={handleDelete} className={styles.delete}>
                <MdDelete />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="date"
              type="date"
              label={t('shared.forms.date')}
              required
              fullWidth
              InputProps={{
                classes: { root: styles.field },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={new Date(createdAt).toISOString().slice(0, 10)}
              onChange={(e) => setCreatedAt(Date.parse(e.target.value))}
            />
          </Grid>
          {showModified && (
            <Grid item xs={6}>
              <TextField
                id="modified-date"
                type="date"
                label={t('journal.entry.lastModified')}
                required
                disabled
                fullWidth
                InputProps={{
                  classes: { root: styles.field },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={new Date(journalEntry?.updatedAt ?? Date.now())
                  .toISOString()
                  .slice(0, 10)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="filled-multiline-static"
              placeholder={t('journal.entry.content')}
              rows={10}
              multiline
              fullWidth
              variant="outlined"
              InputProps={{
                classes: {
                  root: styles.field,
                },
              }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button className="ml-auto" onClick={handleSubmit}>
              {t('journal.entry.submit')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JournalEntry;
