import { navigate } from 'gatsby';
import React, { memo } from 'react';
import LoadingScreen from './LoadingScreen';
import useSelector from '../../hooks/useAppSelector';

import { selectUser, selectUserIsLoading } from '../../redux/slices/userSlice';

const PrivateRoute = ({ component: Component, ...props }) => {
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectUserIsLoading);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (user == null) {
    navigate('/');
    return null;
  }

  return <Component user={user} {...props} />;
};

export default memo(PrivateRoute);
