import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { List, Container } from '@material-ui/core';

import NavigationButton from '../../components/shared/NavigationButton';
import LoadingScreen from '../../components/router/LoadingScreen';
import Button from '../../components/shared/Button';
import TopNavbar from '../../components/shared/TopNavbar';
import JournalListItem from '../../components/journal/JournalListItem';
import FilterType from '../../components/journal/Filter';

import getJournalItems from '../../api/journal/getJournalItems';

import useWPLH from '../../hooks/useWPLH';
import useJournalEntry from '../../hooks/useJournalEntry';
import usePieChart from '../../hooks/usePieChart';
import useSCurve from '../../hooks/useSCurve';

import { User } from '../../types/User';
import { JournalItem, JournalItemType } from '../../types/JournalItem';

import styles from './journal.module.css';

const Journal = ({ user }: { user: User }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<JournalItemType | 'all'>('all');
  const [journalItems, setJournalItems] = useState<JournalItem[]>([]);
  const { deleteJournalEntry, isUpdating: entryIsUpdating } = useJournalEntry();
  const { deleteWPLH, isUpdating: WPLHIsUpdating } = useWPLH();
  const { deletePieChart, isUpdating: pieChartIsUpdating } = usePieChart();
  const { deleteSCurve, isUpdating: sCurveIsUpdating } = useSCurve();

  useEffect(() => {
    if (
      entryIsUpdating ||
      WPLHIsUpdating ||
      pieChartIsUpdating ||
      sCurveIsUpdating
    ) {
      return;
    }
    (async () => {
      const data = await getJournalItems(user.uid);
      if (!data) {
        navigate('app/journal/activity/wplh');
        toast.error(t('activity.wplh.toasts.doesNotExist'));
        return null;
      }
      data.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      setJournalItems(data);
      setLoading(false);
    })();
  }, [entryIsUpdating, WPLHIsUpdating, pieChartIsUpdating, sCurveIsUpdating]);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleEntryDelete = (title: string, id: string) => {
    switch (title) {
      case 'WPLH':
        deleteWPLH(id);
        break;
      case 'piechart':
        deletePieChart(id);
        break;
      case 'sCurve':
        deleteSCurve(id);
        break;
      default:
        deleteJournalEntry(id);
        break;
    }
    const journalItemsUpdated = journalItems.filter((item) => item.id !== id);
    setJournalItems(journalItemsUpdated);
  };

  return (
    <div>
      <TopNavbar />
      <Container className={styles.container}>
        <div className={`flex justify-between ${styles.headers}`}>
          <h1>{t('journal.title')}</h1>
          <NavigationButton onClick={() => navigate('/app/timeline')} />
        </div>
        <div className={`flex p-2 ${styles.headers}`}>
          <Button
            onClick={() => navigate('/app/journal/activity')}
            className={`${styles.button} ${styles.red}`}
          >
            {t('journal.doActivity')}
          </Button>
          <Button
            onClick={() => navigate('/app/journal/entry')}
            className={`${styles.button} ${styles.blue}`}
          >
            {t('journal.doEntry')}
          </Button>
          <div className={styles.end}>
            <FilterType filter={filter} setFilter={setFilter} />
          </div>
        </div>

        <div className={styles.item}>
          <List>
            {journalItems
              .filter((item) => filter === item.type || filter === 'all')
              .map((item) => (
                <JournalListItem
                  item={item}
                  key={item.id}
                  handleEntryDelete={handleEntryDelete}
                />
              ))}
          </List>
        </div>
      </Container>
    </div>
  );
};

export default Journal;
