import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

import { MdDelete } from 'react-icons/md';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '../shared/Button';

import styles from './JournalListItem.module.css';
import ModalContext from '../../contexts/ModalContext';

import {
  JournalItem,
  JournalItemEnum,
  JournalItemType,
} from '../../types/JournalItem';

const getNavigatePath = (title: string, type: JournalItemType, key: string) => {
  let hrefSend = '';
  switch (type) {
    case JournalItemEnum.entry:
      hrefSend = `/app/journal/entry/${key}`;
      break;
    default:
      break;
    case JournalItemEnum.activity:
      switch (title) {
        case 'WPLH':
          hrefSend = `/app/journal/activity/wplh/${key}`;
          break;
        case 'piechart':
          hrefSend = `/app/journal/activity/piechart/${key}`;
          break;
        case 'sCurve':
          hrefSend = `/app/journal/activity/scurve/${key}`;
          break;
        default:
          break;
      }
  }
  return hrefSend;
};

const getActionText = (isSubmitted: boolean, type: JournalItemType) => {
  if (isSubmitted) {
    return type === JournalItemEnum.entry
      ? 'journal.journalListItem.viewEntry'
      : 'journal.journalListItem.viewActivity';
  }
  return type === JournalItemEnum.entry
    ? 'journal.journalListItem.completeEntry'
    : 'journal.journalListItem.completeActivity';
};

const JournalListItem = ({
  item,
  handleEntryDelete,
}: {
  item: JournalItem;
  handleEntryDelete: (title: string, id: string) => void;
}) => {
  const { t } = useTranslation();

  const { emitter, events } = useContext(ModalContext);

  const handleDelete = () => {
    emitter.emit(events.DELETE_JOURNAL_ENTRY_MODAL, {
      id: item.id,
      title: item.title,
      deleteAction: handleEntryDelete,
      isEntry: false,
    });
  };

  return (
    <ListItem
      className={`${styles.journalListItem} ${
        item.type === JournalItemEnum.entry ? styles.entry : styles.activity
      } ${!item.isSubmitted && styles.notSubmitted}`}
    >
      <ListItemText
        primary={
          <div className={styles.headings}>
            <div>
              {`${item.title} ${
                !item.isSubmitted ? t('journal.journalListItem.incomplete') : ''
              }`}
            </div>
            <div>{new Date(item.createdAt).toDateString()}</div>
          </div>
        }
        secondary={
          <Button
            className={styles.linkButton}
            onClick={() => {
              navigate(getNavigatePath(item.title, item.type, item.id));
            }}
          >
            {t(getActionText(item.isSubmitted, item.type))}
          </Button>
        }
      />
      <ListItemSecondaryAction>
        <IconButton onClick={handleDelete} className={styles.delete}>
          <MdDelete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default JournalListItem;
