import { Redirect, Router } from '@reach/router';
import React, { memo } from 'react';
import PrivateRoute from '../components/router/PrivateRoute';
import Wrapper from '../components/shared/Wrapper';
import NotFound from './404';
import Builder from './app/builder';
import Timeline from './app/timeline';
import Journal from './app/journal';
import JournalEntry from './app/journal/entry';
import Activity from './app/journal/activity';
import PieChart from './app/journal/activity/piechart';
import SCurve from './app/journal/activity/scurve';
import WPLH from './app/journal/activity/wplh';

const App = () => (
  <Wrapper>
    <Router basepath="/app">
      <Redirect noThrow from="/" to="/app/timeline" exact default />
      <PrivateRoute path="/timeline" component={Timeline} />
      <PrivateRoute path="/builder/:id" component={Builder} exact />
      <PrivateRoute path="/journal" component={Journal} />
      <PrivateRoute path="/journal/entry" component={JournalEntry} exact />
      <PrivateRoute path="/journal/entry/:id" component={JournalEntry} />
      <Activity path="/journal/activity" />
      <PieChart path="/journal/activity/piechart" />
      <PieChart path="/journal/activity/piechart/:id" />
      <SCurve path="/journal/activity/scurve" exact />
      <SCurve path="/journal/activity/scurve/:id" />
      <WPLH path="/journal/activity/wplh" exact />
      <WPLH path="/journal/activity/wplh/:id" />

      <NotFound default />
    </Router>
  </Wrapper>
);
export default memo(App);
